export const ACTIONS = {
  CHANGE_PASSWORD_INIT: 'CHANGE_PASSWORD_INIT',
  CHANGE_PASSWORD_SUCCEDED: 'CHANGE_PASSWORD_SUCCEDED',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
  CHANGE_DATA_INIT: 'CHANGE_DATA_INIT',
  CHANGE_DATA_SUCCEDED: 'CHANGE_DATA_SUCCEDED',
  CHANGE_DATA_FAILED: 'CHANGE_DATA_FAILED',
  DELETE_DATA_INIT: 'DELETE_DATA_INIT',
  DELETE_DATA_SUCCEDED: 'DELETE_DATA_SUCCEDED',
  DELETE_DATA_FAILED: 'DELETE_DATA_FAILED',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: 'RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED',

  GET_QUESTIONNAIRES_INIT: 'questionnaire@GET_QUESTIONNAIRES_INIT',
  GET_QUESTIONNAIRES_SUCCEDED: 'questionnaire@GET_QUESTIONNAIRES_SUCCEDED',
  GET_QUESTIONNAIRES_FAILED: 'questionnaire@GET_QUESTIONNAIRES_FAILED',
  UPDATE_MEDICAL_RECORD_INIT: 'UPDATE_MEDICAL_RECORD_INIT',
  UPDATE_MEDICAL_RECORD_SUCCEDED: 'UPDATE_MEDICAL_RECORD_SUCCEDED',
  UPDATE_MEDICAL_RECORD_FAILED: 'UPDATE_MEDICAL_RECORD_FAILED',

  POST_FIRST_CONNECTION_INIT: 'POST_FIRST_CONNECTION_INIT',
  POST_FIRST_CONNECTION_SUCCEDED: 'POST_FIRST_CONNECTION_SUCCEDED',
  POST_FIRST_CONNECTION_FAILED: 'POST_FIRST_CONNECTION_FAILED',

  POST_WELCOME_MSG_INIT: 'POST_WELCOME_MSG_INIT',
  POST_WELCOME_MSG_SUCCEDED: 'POST_WELCOME_MSG_SUCCEDED',
  POST_WELCOME_MSG_FAILED: 'POST_WELCOME_MSG_FAILED',

  DELETE_ACOUNT_EMPLOYEE_INIT: 'DELETE_ACOUNT_EMPLOYEE_INIT',
  DELETE_ACOUNT_EMPLOYEE_SUCCEDED: 'DELETE_ACOUNT_EMPLOYEE_SUCCEDED',
  DELETE_ACOUNT_EMPLOYEE_FAILED: 'DELETE_ACOUNT_EMPLOYEE_FAILED'
};

export const ENDPOINT = {
  QUESTIONNAIRES_EMPLOYEE: '/api/employee/questionnaires',

  CHANGE_PASSWORD_EMPLOYEE: '/api/auth/change_password',
  CHANGE_DATA_EMPLOYEE: '/api/employee/request_update_data',
  DELETE_DATA_EMPLOYEE: '/api/employee/request_delete_data',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE: '/api/employee/questionnaire',
  UPDATE_MEDICAL_RECORD: '/api/medical-record',

  POST_FIRST_CONNECTION: '/api/employee/first_login',
  POST_WELCOME_MSG: '/api/employee/welcome_msg',

  DELETE_ACOUNT_EMPLOYEE: '/api/employee'
};
