export const ACTIONS = {
  GET_CHECKIN_SLEEP_INIT: 'GET_CHECKIN_SLEEP_INIT',
  GET_CHECKIN_SLEEP_SUCCEDED: 'GET_CHECKIN_SLEEP_SUCCEDED',
  GET_CHECKIN_SLEEP_FAILED: 'GET_CHECKIN_SLEEP_FAILED',

  SAVE_CHECKIN_SLEEP_INIT: 'SAVE_CHECKIN_SLEEP_INIT',
  SAVE_CHECKIN_SLEEP_SUCCEDED: 'SAVE_CHECKIN_SLEEP_SUCCEDED',
  SAVE_CHECKIN_SLEEP_FAILED: 'SAVE_CHECKIN_SLEEP_FAILED'
};

export const ENDPOINT = {
  SAVE_CHECKIN_SLEEP: '/api/employee/checkins/sleep',
  GET_CHECKIN_SLEEP: '/api/employee/checkins/sleep'
};
