import React from 'react';

const MedicalRecord = React.lazy(
  () => import('../../modules/employee/medicalRecord/containers/MedicalRecord')
);
const EditMedicalRecord = React.lazy(
  () =>
    import('../../modules/employee/medicalRecord/containers/EditMedicalRecord')
);

export const medicalRecord = {
  path: '/medical/record',
  component: MedicalRecord
};

export const editMedicalRecord = {
  path: '/edit/medical/record',
  component: EditMedicalRecord
};
