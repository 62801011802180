import React from 'react';

const ChangePassword = React.lazy(
  () => import('../../modules/moderator/password/containers/ChangePassword')
);

export const change_password = {
  path: '/changepassword',
  component: ChangePassword
};
