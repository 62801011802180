import { sessionService } from 'redux-react-session';
import { ACTIONS } from './../constants';
import { checkinsport } from '../../../../../routes/employee/checkins';

export default (
  state = {
    checkinsport: {},
    isLoadingCheckinSport: false
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.SAVE_CHECKIN_SPORT_INIT: {
      /*       const oldPayload = { ...state };
      console.log('oldpayload in init', oldPayload) */
      return {
        ...state,
        isLoadingCheckinSport: true,
        checkinsport: {},
        error: null
      };
    }
    case ACTIONS.SAVE_CHECKIN_SPORT_SUCCEDED: {
      const newData = { ...state };
      return {
        ...state,
        isLoadingCheckinSport: false,
        checkinsport: newData.checkinsport,
        error: null
      };
    }
    case ACTIONS.SAVE_CHECKIN_SPORT_FAILED: {
      return {
        ...state,
        isLoadingCheckinSport: false,
        checkinsport: {},
        error: payload
      };
    }
    case ACTIONS.GET_CHECKIN_SPORT_INIT: {
      return {
        ...state,
        isLoadingCheckinSport: true,
        checkinsport: {},
        error: null
      };
    }
    case ACTIONS.GET_CHECKIN_SPORT_SUCCEDED: {
      return {
        ...state,
        isLoadingCheckinSport: false,
        checkinsport: payload,
        error: null
      };
    }
    case ACTIONS.GET_CHECKIN_SPORT_FAILED: {
      return {
        ...state,
        isLoadingCheckinSport: false,
        checkinsport: {},
        error: payload
      };
    }
    default: {
      return state;
    }
  }
};
