export const ACTIONS = {
  GET_CALENDAR_INIT: 'GET_CALENDAR_INIT',
  GET_CALENDAR_SUCCEDED: 'GET_CALENDAR_SUCCEDED',
  GET_CALENDAR_FAILED: 'GET_CALENDAR_FAILED',

  SAVE_CALENDAR_INIT: 'SAVE_CALENDAR_INIT',
  SAVE_CALENDAR_SUCCEDED: 'SAVE_CALENDAR_SUCCEDED',
  SAVE_CALENDAR_FAILED: 'SAVE_CALENDAR_FAILED'
};

export const ENDPOINT = {
  SAVE_CALENDAR: '/api/employee/calendar',
  GET_CALENDAR: '/api/employee/calendar'
};
