import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import postProcessor from 'i18next-sprintf-postprocessor';
import translationEN from './locales/en/translation.json';
import evolutionTranslationEN from './locales/en/evolutionTranslation.json';

// the translations
const resources = {
  en: {
    translation: { ...translationEN, ...evolutionTranslationEN }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Cache)
  .init({
    lng: i18n.options.lng,
    fallbackLng: i18n.options.lng,
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false, // we do not use keys in form messages:welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
i18n.languages = ['fr', 'en'];

export default i18n;
