export const ACTIONS = {
  GET_ARTICLES_PER_PAGE_INIT: 'articles@GET_ARTICLES_PER_PAGE_INIT',
  GET_ARTICLES_PER_PAGE_SUCCEDED: 'articles@GET_ARTICLES_PER_PAGE_SUCCEDED',
  GET_ARTICLES_PER_PAGE_FAILED: 'articles@GET_ARTICLES_PER_PAGE_FAILED',
  GET_ALL_ARTICLES_INIT: 'articles@GET_ALL_ARTICLES_INIT',
  GET_ALL_ARTICLES_SUCCEDED: 'articles@GET_ALL_ARTICLES_SUCCEDED',
  GET_ALL_ARTICLES_FAILED: 'articles@GET_ALL_ARTICLES_FAILED',
  GET_ARTICLE_IMAGE_INIT: 'articles@GET_ARTICLE_IMAGE_INIT',
  GET_ARTICLE_IMAGE_SUCCEDED: 'articles@GET_ARTICLE_IMAGE_SUCCEDED',
  GET_ARTICLE_IMAGE_FAILED: 'articles@GET_ARTICLE_IMAGE_FAILED'
};

export const ENDPOINT = {
  RETREIVE_ARTICLES:
    'https://blog.predictanalyse.com/index.php/wp-json/wp/v2/posts?_embed&per_page=:numPost&page=:page'
};
