export * from './env';
export * from './actions';
export * from './uiHelpers';

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const ROLES = {
  ADMIN: 1,
  MODERATOR: 2,
  EMPLOYEE: 4
};
