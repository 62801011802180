import { ACTIONS } from '../constants';

export default (
  state = { isLoading: false, notificationsMessages: [], error: null },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.FETCH_UNREAD_MESSAGES_INIT: {
      return {
        ...state,
        isLoading: true,
        notificationsMessages: [],
        error: null
      };
    }
    case ACTIONS.FETCH_UNREAD_MESSAGES_SUCCEDED: {
      return {
        ...state,
        isLoading: false,
        notificationsMessages: payload.notifications,
        error: null
      };
    }
    case ACTIONS.FETCH_UNREAD_MESSAGES_FAILED: {
      return {
        ...state,
        isLoading: false,
        notificationsMessages: [],
        error: null
      };
    }
    default: {
      return state;
    }
  }
};
