export const ACTIONS = {
  FETCH_DEPARMTNETS_INIT: 'nutrition@FETCH_DEPARMTNETS_INIT',
  FETCH_DEPARMTNETS_SUCCEDED: 'nutrition@FETCH_DEPARMTNETS_SUCCEDED',
  FETCH_DEPARMTNETS_FAILED: 'nutrition@FETCH_DEPARMTNETS_FAILED',
  FETCH_DETAILS_OF_TROUBLES_INIT: 'nutrition@FETCH_DETAILS_OF_TROUBLES_INIT',
  FETCH_DETAILS_OF_TROUBLES_SUCCEDED:
    'nutrition@FETCH_DETAILS_OF_TROUBLES_SUCCEDED',
  FETCH_DETAILS_OF_TROUBLES_FAILED:
    'nutrition@FETCH_DETAILS_OF_TROUBLES_FAILED',
  FETCH_NEED_FOR_INTERVENTIONS_INIT:
    'nutrition@FETCH_NEED_FOR_INTERVENTIONS_INIT',
  FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED:
    'nutrition@FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED',
  FETCH_NEED_FOR_INTERVENTIONS_FAILED:
    'nutrition@FETCH_NEED_FOR_INTERVENTIONS_FAILED',
  MORE_THRESHOLD_INIT: 'MORE_THRESHOLD_INIT',
  MORE_THRESHOLD_SUCCEDED: 'MORE_THRESHOLD_SUCCEDED',
  MORE_THRESHOLD_FAILED: 'MORE_THRESHOLD_FAILED'
};

export const ENDPOINT = {
  ALL_DEPARTMENTS: '/api/company/:companyParam/departments/all',
  DETAILS_OF_TROUBLES:
    '/api/nutrition-monitoring/details-of-troubles/:deparmentParam',
  NEED_FOR_INTERVENTIONS:
    '/api/nutrition-monitoring/need-for-intervention/:deparmentParam',
  DETAILS_OF_TROUBLES_COMPANY:
    '/api/company/:companyParam/nutrition-monitoring/details-of-troubles',
  NEED_FOR_INTERVENTIONS_COMPANY:
    '/api/company/:companyParam/nutrition-monitoring/need-for-intervention',
  MORE_THRESHOLD_COMPANY: '/api/company/:Param/departments/more_threshold '
};
