import { sessionService } from 'redux-react-session';
import { ACTIONS } from './../constants';
import { calendar } from '../../../../routes/employee/scheduler';

export default (
  state = {
    calendar: {},
    isLoadingCalendar: false
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.SAVE_CALENDAR_INIT: {
      return { ...state, isLoadingCalendar: true, calendar: {}, error: null };
    }
    case ACTIONS.SAVE_CALENDAR_SUCCEDED: {
      const newData = { ...state };
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: newData.calendar,
        error: null
      };
    }
    case ACTIONS.SAVE_CALENDAR_FAILED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: {},
        error: payload
      };
    }
    case ACTIONS.GET_CALENDAR_INIT: {
      return { ...state, isLoadingCalendar: true, calendar: {}, error: null };
    }
    case ACTIONS.GET_CALENDAR_SUCCEDED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: payload,
        error: null
      };
    }
    case ACTIONS.GET_CALENDAR_FAILED: {
      return {
        ...state,
        isLoadingCalendar: false,
        calendar: {},
        error: payload
      };
    }
    default: {
      return state;
    }
  }
};
