import React from 'react';
const Result = React.lazy(
  () => import('../../modules/employee/results/containers/result')
);
const Results = React.lazy(
  () => import('../../modules/employee/results/containers/results')
);
const Resultsv2 = React.lazy(
  () => import('../../modules/employee/results/containers/resultv2')
);

export const results = {
  path: '/results',
  component: Results
};

export const result = {
  path: '/result/:param',
  component: Result
};

export const resultsv2 = {
  path: '/resultsv2/:param',
  component: Resultsv2
};
