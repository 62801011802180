import React from 'react';

const Checkins = React.lazy(
  () => import('../../modules/employee/checkins/Checkins')
);
const CheckinSport = React.lazy(
  () => import('../../modules/employee/checkins/sport/CheckinSport')
);
const CheckinSportAdd = React.lazy(
  () => import('../../modules/employee/checkins/sport/CheckinSportAdd')
);
const CheckinSleep = React.lazy(
  () => import('../../modules/employee/checkins/sleep/CheckinSleep')
);
const CheckinSleepAdd = React.lazy(
  () => import('../../modules/employee/checkins/sleep/CheckinSleepAdd')
);
const CheckinEmotions = React.lazy(
  () => import('../../modules/employee/checkins/emotions/CheckinEmotions')
);
const CheckinEmotionsAdd = React.lazy(
  () => import('../../modules/employee/checkins/emotions/CheckinEmotionsAdd')
);
const CheckinSmoking = React.lazy(
  () => import('../../modules/employee/checkins/smoking/CheckinSmoking')
);
const CheckinSmokingAdd = React.lazy(
  () => import('../../modules/employee/checkins/smoking/CheckinSmokingAdd')
);

export const checkins = {
  path: '/checkins',
  component: Checkins
};

export const checkinsport = {
  path: '/checkin/sport',
  component: CheckinSport
};

export const checkinsportadd = {
  path: '/add-checkin-sport',
  component: CheckinSportAdd
};

export const checkinsleep = {
  path: '/checkin/sleep',
  component: CheckinSleep
};

export const checkinsleepadd = {
  path: '/add-checkin-sleep',
  component: CheckinSleepAdd
};

export const checkinemotions = {
  path: '/checkin/emotions',
  component: CheckinEmotions
};

export const checkinemotionsadd = {
  path: '/add-checkin-emotions',
  component: CheckinEmotionsAdd
};

export const checkinsmoking = {
  path: '/checkin/smoking',
  component: CheckinSmoking
};

export const checkinsmokingadd = {
  path: '/add-checkin-smoking',
  component: CheckinSmokingAdd
};
