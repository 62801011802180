import React from 'react';

const ProgramsPage = React.lazy(
  () => import('../../modules/employee/programs/containers/programContainer')
);
const LumbarStretchProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/LumbarStretch'
    )
);
const LumbarStrengtheningProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/LumbarStrengthening'
    )
);
const LumbarStrengtheningProgram2 = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/LumbarStrengthening2'
    )
);
const FractionneProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Fractionne'
    )
);
const YogaProgram = React.lazy(
  () =>
    import('../../modules/employee/programs/components/specificPrograms/Yoga')
);
const CervicalMobilisationProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/CervicalMobilisation'
    )
);
const CervicalStrengtheningProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/CervicalStrengthening'
    )
);
const ShoulderStrengtheningProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/ShoulderStrengthening'
    )
);
const ShoulderMobilisationProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/ShoulderMobilisation'
    )
);
const HipStretchingProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/HipStretching'
    )
);
const HipStrengtheningProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/HipStrengthening'
    )
);
const KneeStretchingProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/KneeStretching'
    )
);
const KneeStrengtheningProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/KneeStrengthening'
    )
);
const WristStretchingProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/WristStretching'
    )
);
const WristCarpalTunnel = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/WristCarpalTunnel'
    )
);
const CardioProgram1 = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Cardio1'
    )
);
const CardioProgram2 = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Cardio2'
    )
);
const CardioProgram3 = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Cardio3'
    )
);
const PainEducation = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/PainEducation'
    )
);
const SleepInformation = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/SleepInfo'
    )
);
const RelaxationProgram = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Relaxation'
    )
);
const TennisElbow = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/TennisElbow'
    )
);
const GolfElbow = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/GolfElbow'
    )
);
const Capsulite = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/Capsulite'
    )
);
const NCB = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/NCB'
    )
);
const SciaticNerve = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/SciaticNerve'
    )
);
const FemoralNerve = React.lazy(
  () =>
    import(
      '../../modules/employee/programs/components/specificPrograms/FemoralNerve'
    )
);

export const programsPage = {
  path: '/programs/:param',
  component: ProgramsPage
};

export const lumbarStretchProgram = {
  path: '/program/lumbar-stretch',
  component: LumbarStretchProgram
};

export const lumbarStrengtheningProgram = {
  path: '/program/lumbar-strengthening',
  component: LumbarStrengtheningProgram
};

export const lumbarStrengtheningProgram2 = {
  path: '/program/lumbar-strengthening2',
  component: LumbarStrengtheningProgram2
};

export const fractionneProgram = {
  path: '/program/fractionne',
  component: FractionneProgram
};

export const yogaProgram = {
  path: '/program/yoga',
  component: YogaProgram
};

export const cervicalMobilisationProgram = {
  path: '/program/cervical-mobilization',
  component: CervicalMobilisationProgram
};

export const cervicalStrengtheningProgram = {
  path: '/program/cervical-strengthening',
  component: CervicalStrengtheningProgram
};

export const shoulderStrengtheningProgram = {
  path: '/program/shoulder-strengthening',
  component: ShoulderStrengtheningProgram
};

export const shoulderMobilisationProgram = {
  path: '/program/shoulder-mobilization',
  component: ShoulderMobilisationProgram
};

export const hipStretchingProgram = {
  path: '/program/hip-stretching',
  component: HipStretchingProgram
};

export const kneeStretchingProgram = {
  path: '/program/knee-stretching',
  component: KneeStretchingProgram
};

export const kneeStrengtheningProgram = {
  path: '/program/knee-strengthening',
  component: KneeStrengtheningProgram
};

export const wristStretchingProgram = {
  path: '/program/wrist-stretching',
  component: WristStretchingProgram
};

export const wristCarpalTunnel = {
  path: '/program/wrist-carpal-tunnel',
  component: WristCarpalTunnel
};

export const hipStrengtheningProgram = {
  path: '/program/hip-strengthening',
  component: HipStrengtheningProgram
};

export const cardioProgram1 = {
  path: '/program/cardio1',
  component: CardioProgram1
};

export const cardioProgram2 = {
  path: '/program/cardio2',
  component: CardioProgram2
};

export const cardioProgram3 = {
  path: '/program/cardio3',
  component: CardioProgram3
};

export const painEducation = {
  path: '/program/pain-education',
  component: PainEducation
};

export const sleepInformation = {
  path: '/program/sleep-information',
  component: SleepInformation
};

export const relaxationProgram = {
  path: '/program/relaxation',
  component: RelaxationProgram
};

export const tennisElbow = {
  path: '/program/tennis-elbow',
  component: TennisElbow
};

export const golfElbow = {
  path: '/program/golf-elbow',
  component: GolfElbow
};

export const capsulite = {
  path: '/program/capsulitis',
  component: Capsulite
};

export const ncb = {
  path: '/program/ncb',
  component: NCB
};

export const sciaticnerve = {
  path: '/program/sciatic-nerve',
  component: SciaticNerve
};

export const femoralnerve = {
  path: '/program/femoral-nerve',
  component: FemoralNerve
};