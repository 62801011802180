import React from 'react';

const HeadachePage = React.lazy(
  () =>
    import('../../modules/employee/adverse_events/containers/headacheContainer')
);
const StressPage = React.lazy(
  () =>
    import('../../modules/employee/adverse_events/containers/stressContainer')
);
const DepressionPage = React.lazy(
  () =>
    import(
      '../../modules/employee/adverse_events/containers/depressionContainer'
    )
);
const PainPage = React.lazy(
  () => import('../../modules/employee/adverse_events/containers/painContainer')
);
const ResultsAnalysis = React.lazy(
  () =>
    import('../../modules/employee/adverse_events/containers/resultv2Analysis')
);
const RiskSimulation = React.lazy(
  () =>
    import('../../modules/employee/adverse_events/components/riskSimulation')
);

export const resultsAnalysis = {
  path: '/results-analyses/:param',
  component: ResultsAnalysis
};

export const riskSimulation = {
  path: '/risk-simulation',
  component: RiskSimulation
};

export const headachePage = {
  path: '/headache',
  component: HeadachePage
};

export const stressPage = {
  path: '/stress',
  component: StressPage
};

export const depressionPage = {
  path: '/depression',
  component: DepressionPage
};

export const painPage = {
  path: '/pain',
  component: PainPage
};
