import React from 'react';

const QuestionnaireDashboard = React.lazy(
  () =>
    import(
      '../../modules/employee/questionnaire_dashboard/containers/Questionnaire'
    )
);

export const questionnaire_dashboard = {
  path: '/result/questionnaire',
  component: QuestionnaireDashboard
};
