export const ACTIONS = {
  GET_CHECKIN_SMOKING_INIT: 'GET_CHECKIN_SMOKING_INIT',
  GET_CHECKIN_SMOKING_SUCCEDED: 'GET_CHECKIN_SMOKING_SUCCEDED',
  GET_CHECKIN_SMOKING_FAILED: 'GET_CHECKIN_SMOKING_FAILED',

  SAVE_CHECKIN_SMOKING_INIT: 'SAVE_CHECKIN_SMOKING_INIT',
  SAVE_CHECKIN_SMOKING_SUCCEDED: 'SAVE_CHECKIN_SMOKING_SUCCEDED',
  SAVE_CHECKIN_SMOKING_FAILED: 'SAVE_CHECKIN_SMOKING_FAILED'
};

export const ENDPOINT = {
  SAVE_CHECKIN_SMOKING: '/api/employee/checkins/smoking',
  GET_CHECKIN_SMOKING: '/api/employee/checkins/smoking'
};
