import { sessionService } from 'redux-react-session';
import { ACTIONS } from '../constants';
import { checkinsleep } from '../../../../../routes/employee/checkins';

export default (
  state = {
    checkinsleep: {},
    isLoadingCheckinSleep: false
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.SAVE_CHECKIN_SLEEP_INIT: {
      /*       const oldPayload = { ...state };
      console.log('oldpayload in init', oldPayload) */
      return {
        ...state,
        isLoadingCheckinSleep: true,
        checkinsleep: {},
        error: null
      };
    }
    case ACTIONS.SAVE_CHECKIN_SLEEP_SUCCEDED: {
      const newData = { ...state };
      return {
        ...state,
        isLoadingCheckinSleep: false,
        checkinsleep: newData.checkinsleep,
        error: null
      };
    }
    case ACTIONS.SAVE_CHECKIN_SLEEP_FAILED: {
      return {
        ...state,
        isLoadingCheckinSleep: false,
        checkinsleep: {},
        error: payload
      };
    }
    case ACTIONS.GET_CHECKIN_SLEEP_INIT: {
      return {
        ...state,
        isLoadingCheckinSleep: true,
        checkinsleep: {},
        error: null
      };
    }
    case ACTIONS.GET_CHECKIN_SLEEP_SUCCEDED: {
      return {
        ...state,
        isLoadingCheckinSleep: false,
        checkinsleep: payload,
        error: null
      };
    }
    case ACTIONS.GET_CHECKIN_SLEEP_FAILED: {
      return {
        ...state,
        isLoadingCheckinSleep: false,
        checkinsleep: {},
        error: payload
      };
    }
    default: {
      return state;
    }
  }
};
