import React from 'react';

const PainScheduler = React.lazy(
  () =>
    import('../../modules/employee/scheduler/containers/painSchedulerContainer')
);
const SleepScheduler = React.lazy(
  () =>
    import(
      '../../modules/employee/scheduler/containers/sleepSchedulerContainer'
    )
);
const StressScheduler = React.lazy(
  () =>
    import(
      '../../modules/employee/scheduler/containers/stressSchedulerContainer'
    )
);
const SedentaryScheduler = React.lazy(
  () =>
    import(
      '../../modules/employee/scheduler/containers/sedentarySchedulerContainer'
    )
);
const Calendar = React.lazy(
  () => import('../../modules/employee/scheduler/containers/calendar')
);

export const painScheduler = {
  path: '/pain-scheduler',
  component: PainScheduler
};

export const sleepScheduler = {
  path: '/sleep-scheduler',
  component: SleepScheduler
};

export const sedentaryScheduler = {
  path: '/sedentary-scheduler',
  component: SedentaryScheduler
};

export const stressScheduler = {
  path: '/stress-scheduler',
  component: StressScheduler
};

export const calendar = {
  path: '/calendar',
  component: Calendar
};
