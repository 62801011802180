import React from 'react';

const messagesCompanies = React.lazy(
  () => import('../../modules/admin/messages/containers/ListCompany')
);
const messagesCompany = React.lazy(
  () => import('../../modules/admin/messages/containers/ListMessagesCompany')
);
const conversation = React.lazy(
  () => import('../../modules/admin/messages/containers/conversation')
);
const PostMessage = React.lazy(
  () => import('../../modules/admin/messages/containers/NewMessage')
);
const MessageSended = React.lazy(
  () => import('../../modules/admin/messages/containers/Successmsg')
);

export const messageCompanies = {
  path: '/messages/companies',
  component: messagesCompanies
};

export const messagelistCompany = {
  path: '/messages/company/:param',
  component: messagesCompany
};

export const conversationAdmin = {
  path: '/messages/conversation/:param',
  component: conversation
};

export const NewConversationAdmin = {
  path: '/messages/:Userparam/answer',
  component: PostMessage
};

export const SendSuccuss = {
  path: '/messages/sucess',
  component: MessageSended
};
