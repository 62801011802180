import i18n from '../../../../i18n';

export const ACTIONS = {
  CREATE_RPS_QUESTIONNAIRE_INIT: 'CREATE_RPS_QUESTIONNAIRE_INIT',
  CREATE_RPS_QUESTIONNAIRE_SUCCEDED: 'CREATE_RPS_QUESTIONNAIRE_SUCCEDED',
  CREATE_RPS_QUESTIONNAIRE_FAILED: 'CREATE_RPS_QUESTIONNAIRE_FAILED',

  NEXT_PAGE: 'NEXT_PAGE',

  FETCH_RPS_QUESTION_INIT: 'FETCH_RPS_QUESTION_INIT',
  FETCH_RPS_QUESTION_SUCCEDED: 'FETCH_RPS_QUESTION_SUCCEDED',
  FETCH_RPS_QUESTION_FAILED: 'FETCH_RPS_QUESTION_FAILED',

  UPDATE_RPS_SCORE: 'UPDATE_RPS_SCORE',
  SAVE_RPS_QUESTION_ANSWER: 'SAVE_RPS_QUESTION_ANSWER',
  RPS_NEXT_QUESTION: 'RPS_NEXT_QUESTION',
  EXIT_PAGE: 'EXIT_PAGE',

  GET_RPS_STATE_INIT: 'GET_RPS_STATE_INIT',
  GET_RPS_STATE_SUCCEDED: 'GET_RPS_STATE_SUCCEDED',
  GET_RPS_STATE_FAILED: 'GET_RPS_STATE_FAILED',

  SAVE_RPS_STATE_INIT: 'SAVE_RPS_STATE_INIT',
  SAVE_RPS_STATE_SUCCEDED: 'SAVE_RPS_STATE_SUCCEDED',
  SAVE_RPS_STATE_FAILED: 'SAVE_RPS_STATE_FAILED',

  SAVE_KARASEK_SCORES_INIT: 'SAVE_KARASEK_SCORES_INIT',
  SAVE_KARASEK_SCORES_SUCCEDED: 'SAVE_KARASEK_SCORES_SUCCEDED',
  SAVE_KARASEK_SCORES_FAILED: 'SAVE_KARASEK_SCORES_FAILED',

  SAVE_SIEGRIST_SCORES_INIT: 'SAVE_SIEGRIST_SCORES_INIT',
  SAVE_SIEGRIST_SCORES_SUCCEDED: 'SAVE_SIEGRIST_SCORES_SUCCEDED',
  SAVE_SIEGRIST_SCORES_FAILED: 'SAVE_SIEGRIST_SCORES_FAILED',

  ADD_COMPLETION: 'ADD_COMPLETION',
  DELETE_COMPLETION: 'DELETE_COMPLETION',
  PREVIOUS_QUESTION: 'PREVIOUS_QUESTION',
  ASK_CONTINUE: 'ASK_CONTINUE',
  ADD_QUESTION: 'ADD_QUESTION',
  MINUS_QUESTION: 'MINUS_QUESTION',
  KARASEK_FINISHED: 'KARASEK_FINISHED'
};
export const ENDPOINT = {
  GET_QUESTION: '/api/tree/:treeparam/node/:nodeparam',
  CREATE_RPS_QUESTIONNAIRE: '/api/employee/:idQues/rps_questionnaires',
  RPS_HISTORY: '/api/rps_questionnaire/:idRpsQuestionnaire/question-history',
  SAVE_RPS_SCORS: '/api/employee/rps_questionnaires/:idRpsQuestionnaire'
};
export const questionsTreeNode = {
  KARASEK: {
    treeparam: '3323877a94f54731a3832f2aedab9d23',
    nodeparam: 'c40bdbf15c474300b1c7567aad0547cb'
  },
  SIEGRIST: {
    treeparam: 'd821f24a07c54357b2782ecdf4f3ae02',
    nodeparam: 'c193b00130af4d66aed7eef45c84997c'
  }
};
