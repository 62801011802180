export const ACTIONS = {
  GET_CHECKIN_EMOTION_INIT: 'GET_CHECKIN_EMOTION_INIT',
  GET_CHECKIN_EMOTION_SUCCEDED: 'GET_CHECKIN_EMOTION_SUCCEDED',
  GET_CHECKIN_EMOTION_FAILED: 'GET_CHECKIN_EMOTION_FAILED',

  SAVE_CHECKIN_EMOTION_INIT: 'SAVE_CHECKIN_EMOTION_INIT',
  SAVE_CHECKIN_EMOTION_SUCCEDED: 'SAVE_CHECKIN_EMOTION_SUCCEDED',
  SAVE_CHECKIN_EMOTION_FAILED: 'SAVE_CHECKIN_EMOTION_FAILED'
};

export const ENDPOINT = {
  SAVE_CHECKIN_EMOTION: '/api/employee/checkins/emotions',
  GET_CHECKIN_EMOTION: '/api/employee/checkins/emotions'
};
