export const ACTIONS = {
  FETCH_DEPARMTNETS_INIT: 'rps@FETCH_DEPARMTNETS_INIT',
  FETCH_DEPARMTNETS_SUCCEDED: 'rps@FETCH_DEPARMTNETS_SUCCEDED',
  FETCH_DEPARMTNETS_FAILED: 'rps@FETCH_DEPARMTNETS_FAILED',
  FETCH_DETAILS_OF_TROUBLES_INIT: 'rps@FETCH_DETAILS_OF_TROUBLES_INIT',
  FETCH_DETAILS_OF_TROUBLES_SUCCEDED: 'rps@FETCH_DETAILS_OF_TROUBLES_SUCCEDED',
  FETCH_DETAILS_OF_TROUBLES_FAILED: 'rps@FETCH_DETAILS_OF_TROUBLES_FAILED',
  FETCH_NEED_FOR_INTERVENTIONS_INIT: 'rps@FETCH_NEED_FOR_INTERVENTIONS_INIT',
  FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED:
    'rps@FETCH_NEED_FOR_INTERVENTIONS_SUCCEDED',
  FETCH_NEED_FOR_INTERVENTIONS_FAILED:
    'rps@FETCH_NEED_FOR_INTERVENTIONS_FAILED',
  MORE_THRESHOLD_INIT: 'MORE_THRESHOLD_INIT',
  MORE_THRESHOLD_SUCCEDED: 'MORE_THRESHOLD_SUCCEDED',
  MORE_THRESHOLD_FAILED: 'MORE_THRESHOLD_FAILED',
  FETCH_DETAILS_OF_KARASEK_INIT: 'rps@FETCH_DETAILS_OF_KARASEK_INIT',
  FETCH_DETAILS_OF_KARASEK_SUCCEDED: 'rps@FETCH_DETAILS_OF_KARASEK_SUCCEDED',
  FETCH_DETAILS_OF_KARASEK_FAILED: 'rps@FETCH_DETAILS_OF_KARASEK_FAILED',
  FETCH_DETAILS_OF_SIEGRIST_INIT: 'rps@FETCH_DETAILS_OF_SIEGRIST_INIT',
  FETCH_DETAILS_OF_SIEGRIST_SUCCEDED: 'rps@FETCH_DETAILS_OF_SIEGRIST_SUCCEDED',
  FETCH_DETAILS_OF_SIEGRIST_FAILED: 'rps@FETCH_DETAILS_OF_SIEGRIST_FAILED'
};

export const ENDPOINT = {
  ALL_DEPARTMENTS: '/api/company/:companyParam/departments/all',
  DETAILS_OF_TROUBLES:
    '/api/rps-monitoring/details-of-troubles/:deparmentParam',
  NEED_FOR_INTERVENTIONS:
    '/api/rps-monitoring/need-for-intervention/:deparmentParam',
  DETAILS_OF_TROUBLES_COMPANY:
    '/api/company/:companyParam/rps-monitoring/details-of-troubles',
  NEED_FOR_INTERVENTIONS_COMPANY:
    '/api/company/:companyParam/rps-monitoring/need-for-intervention',
  MORE_THRESHOLD_COMPANY: '/api/company/:Param/departments/more_threshold ',
  DETAILS_OF_SIEGRIST:
    '/api/department/rps-monitoring/siegrist/:deparmentParam',
  DETAILS_OF_SIEGRIST_COMPANY:
    '/api/company/rps-monitoring/siegrist/:companyParam',
  DETAILS_OF_KARASEK: '/api/department/rps-monitoring/karasek/:deparmentParam',
  DETAILS_OF_KARASEK_COMPANY:
    '/api/company/rps-monitoring/karasek/:companyParam'
};
