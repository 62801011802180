export const ACTIONS = {
  FETCH_COMPANIES_INIT: 'FETCH_COMPANIES_INIT',
  FETCH_COMPANIES_SUCCEDED: 'FETCH_COMPANIES_SUCCEDED',
  FETCH_COMPANIES_FAILED: 'FETCH_COMPANIES_FAILED',

  FETCH_MESSAGES_INIT: 'FETCH_MESSAGES_INIT',
  FETCH_MESSAGES_SUCCEDED: 'FETCH_MESSAGES_SUCCEDED',
  FETCH_MESSAGES_FAILED: 'FETCH_MESSAGES_FAILED',

  FETCH_MESSAGES_EMPLOYEE_INIT: 'FETCH_MESSAGES_EMPLOYEE_INIT',
  FETCH_MESSAGES_EMPLOYEE_SUCCEDED: 'FETCH_MESSAGES_EMPLOYEE_SUCCEDED',
  FETCH_MESSAGES_EMPLOYEE_FAILED: 'FETCH_MESSAGES_EMPLOYEE_FAILED',

  POST_NEW_MESSAGES_INIT: 'POST_NEW_MESSAGES_INIT',
  POST_NEW_MESSAGES_SUCCEDED: 'POST_NEW_MESSAGES_SUCCEDED',
  POST_NEW_MESSAGES_FAILED: 'POST_NEW_MESSAGES_FAILED',

  READ_MESSAGES_INIT: 'FETCH_MESSAGES_INIT',
  READ_MESSAGES_SUCCEDED: 'READ_MESSAGES_SUCCEDED',
  READ_MESSAGES_FAILED: 'READ_MESSAGES_FAILED',

  FILTER_COMPANIES: 'FILTER_COMPANIES'
};

export const ENDPOINT = {
  COMPANIES: '/api/companies',
  FETCH_MESSAGES: '/api/messages/companies',
  POST_MESSAGES_EMPLOYEE: '/api/employee/:param/messages',
  READ_MESSAGES_EMPLOYEE: '/api/message/:param/read'
};
