export const ACTIONS = {
  FETCH_DEPARTMENTS_INIT: 'FETCH_DEPARTMENTS_INIT',
  FETCH_DEPARTMENTS_SUCCEDED: 'FETCH_DEPARTMENTS_SUCCEDED',
  FETCH_DEPARTMENTS_FAILED: 'FETCH_DEPARTMENTS_FAILED',

  CLEAR_DEPARTMENT_FORM: 'CLEAR_DEPARTMENT_FORM',

  CREATE_DEPARTMENT_INIT: 'CREATE_DEPARTMENT_INIT',
  CREATE_DEPARTMENT_SUCCEDED: 'CREATE_DEPARTMENT_SUCCEDED',
  CREATE_DEPARTMENT_FAILED: 'CREATE_DEPARTMENT_FAILED',

  FETCH_DEPARTMENT_INIT: 'FETCH_DEPARTMENT_INIT',
  FETCH_DEPARTMENT_SUCCEDED: 'FETCH_DEPARTMENT_SUCCEDED',
  FETCH_DEPARTMENT_FAILED: 'FETCH_DEPARTMENT_FAILED',

  EDIT_DEPARTMENT_INIT: 'EDIT_DEPARTMENT_INIT',
  EDIT_DEPARTMENT_SUCCEDED: 'EDIT_DEPARTMENT_SUCCEDED',
  EDIT_DEPARTMENT_FAILED: 'EDIT_DEPARTMENT_FAILED',

  DELETE_DEPARTMENT_INIT: 'DELETE_DEPARTMENT_INIT',
  DELETE_DEPARTMENT_SUCCEDED: 'DELETE_DEPARTMENT_SUCCEDED',
  DELETE_DEPARTMENT_FAILED: 'DELETE_DEPARTMENT_FAILED',

  FILTER_DEPARTMENTS: 'FILTER_DEPARTMENTS'
};

export const ENDPOINT = {
  DEPARTMENTS: '/api/company/:companyParam/departments',
  DEPARTMENT: '/api/company/:companyParam/department/:param'
};
