import React from 'react';

const Evolution = React.lazy(
  () => import('../../modules/employee/evolution/containers/Evolution')
);

const EvolutionItem = React.lazy(
  () => import('../../modules/employee/evolution/components/EvolutionItemPage')
);

export const evolution = {
  path: '/evolution',
  component: Evolution
};

export const evolutionItem = {
  path: '/evolution-type/:evolutionItem',
  component: EvolutionItem
};
