import { sessionService } from 'redux-react-session';
import { ACTIONS } from './../constants';

export default (state = { isLoading: false, error: null }, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.UPDATE_EMAIL_INIT: {
      return { ...state, isLoading: true };
    }
    case ACTIONS.UPDATE_EMAIL_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }
    case ACTIONS.UPDATE_EMAIL_SUCCEDED: {
      sessionService.loadUser().then((res) => {
        sessionService.saveUser({ ...res, updateEmail: true });
      });
      return { ...state, error: null, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
