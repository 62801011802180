import React from 'react';

const GeneralData = React.lazy(
  () => import('../../modules/moderator/generalData/containers/GeneralData')
);

export const generalData = {
  path: '/generalData',
  component: GeneralData
};
