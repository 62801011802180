import { reducer as notificationReducer } from 'react-notification-system-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { sessionReducer } from 'redux-react-session';
import thunk from 'redux-thunk';

// middlewares
import { api, localCall, questionApi, questionFoodApi } from './middleware/api';
import { deleteSession, saveSession } from './middleware/session';

// reducers
// admin
import companyReducer from './modules/admin/company/reducers';
import dashbordAdminReducer from './modules/admin/dashbord/reducers';
import inviteManagerReducer from './modules/admin/inviteManager/reducers';
import AdminLayout from './modules/admin/layout/reducers';
import managerReducer from './modules/admin/manager/reducers';
import MessagesReducer from './modules/admin/messages/reducers';
import employeeRapportReducer from './modules/admin/questionnaire/reducers';
import AdminSubAdminReducer from './modules/admin/subadmin/reducers';

// anonymous
import authReducer from './modules/anonymous/authentication/reducers';

// employee
import checkinEmotionsReducer from './modules/employee/checkins/emotions/reducers';
import checkinSleepReducer from './modules/employee/checkins/sleep/reducers';
import checkinSmokingReducer from './modules/employee/checkins/smoking/reducers';
import checkinSportReducer from './modules/employee/checkins/sport/reducers';
import DashboardEmployeeReducer from './modules/employee/dashboard/reducers';
import EvolutionEmployeeReducer from './modules/employee/evolution/reducers';
import LayoutEmployeeReducer from './modules/employee/layout/reducers';
import ArticlesEmployeeReducer from './modules/employee/mediaLibrary/reducers';
import medicalRecordReducer from './modules/employee/medicalRecord/reducers';
import MessagesEmployeeReducer from './modules/employee/Messages/reducers';
import questionnnaireNutritionReducer from './modules/employee/nutrition/reducers';
import questionnaireReducer from './modules/employee/questionnaire/reducers';
import questionnaireDashboardReducer from './modules/employee/questionnaire_dashboard/reducers';
import ResultsEmployeeReducer from './modules/employee/results/reducers';
import rpsQuestionnaireReducer from './modules/employee/rps_questionnaire/reducers';
import calendarReducer from './modules/employee/scheduler/reducers';
import updateEmailEmployee from './modules/employee/updateEmail/reducers';

// moderator
import dashbordModeratorReducer from './modules/moderator/dashbord/reducers';
import departmentReducer from './modules/moderator/department/reducers';
import employeeReducer from './modules/moderator/employee/reducers';
import ergonomicsReducer from './modules/moderator/ergonomics/reducers';
import generalDataManager from './modules/moderator/generalData/reducers';
import inviteEmployeeReducer from './modules/moderator/inviteEmployee/reducers';
import nutritionReducer from './modules/moderator/nutrition/reducers';
import changePasswordManager from './modules/moderator/password/reducers';
import physicalReducer from './modules/moderator/physical/reducers';
import rpsReducer from './modules/moderator/rps/reducers';
import supportReducer from './modules/moderator/support/reducers';
import tmsReducer from './modules/moderator/tms/reducers';

const appReducer = combineReducers({
  // anonymous
  auth: authReducer,

  // common
  session: sessionReducer,
  form: formReducer,
  notifications: notificationReducer,

  // admin
  company: companyReducer,
  dashbordAdmin: dashbordAdminReducer,
  manager: managerReducer,
  inviteManager: inviteManagerReducer,
  employeeRapport: employeeRapportReducer,
  EmployeeMessages: MessagesReducer,
  AdminLayout: AdminLayout,
  AdminSubAdmin: AdminSubAdminReducer,

  // moderator
  department: departmentReducer,
  employee: employeeReducer,
  dashbordModerator: dashbordModeratorReducer,
  inviteEmployee: inviteEmployeeReducer,
  rps: rpsReducer,
  tms: tmsReducer,
  ergonomics: ergonomicsReducer,
  physical: physicalReducer,
  nutritionModerator: nutritionReducer,
  support: supportReducer,
  changePasswordManager: changePasswordManager,
  generalDataManager: generalDataManager,

  // employee
  questionnairedashboard: questionnaireDashboardReducer,
  medicalRecord: medicalRecordReducer,
  questionnaire: questionnaireReducer,
  nutrition: questionnnaireNutritionReducer,
  checkinSport: checkinSportReducer,
  checkinSleep: checkinSleepReducer,
  checkinEmotions: checkinEmotionsReducer,
  checkinSmoking: checkinSmokingReducer,
  dashboardEmployee: DashboardEmployeeReducer,
  LayoutEmployee: LayoutEmployeeReducer,
  MessagesEmployee: MessagesEmployeeReducer,
  ResultsEmployee: ResultsEmployeeReducer,
  articlesEmployee: ArticlesEmployeeReducer,
  evolutionEmployee: EvolutionEmployeeReducer,
  updateEmailEmployee: updateEmailEmployee,
  rpsQuestionnaire: rpsQuestionnaireReducer,
  calendar: calendarReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'ACCOUNT_LOGOUT_SUCCEDED') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default (preloadedState = {}) => {
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        api,
        questionApi,
        questionFoodApi,
        localCall,
        deleteSession,
        saveSession
      )
    )
  );

  return store;
};
