import { ACTIONS } from './../constants';

export default (
  state = {
    articles: [],
    articleImages: [],
    pages: null,
    isLoading: false,
    error: null
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.GET_ARTICLES_PER_PAGE_INIT: {
      return { ...state, articles: [], isLoading: true };
    }
    case ACTIONS.GET_ARTICLES_PER_PAGE_SUCCEDED: {
      return {
        ...state,
        articles: Object.values(payload),
        isLoading: false
      };
    }
    case ACTIONS.GET_ARTICLES_PER_PAGE_FAILED: {
      return { ...state, articles: [], isLoading: false };
    }
    case ACTIONS.GET_ALL_ARTICLES_INIT: {
      return { ...state, pages: null, isLoading: true };
    }
    case ACTIONS.GET_ALL_ARTICLES_SUCCEDED: {
      return {
        ...state,
        pages: Object.values(payload).length / 8,
        isLoading: false
      };
    }
    case ACTIONS.GET_ALL_ARTICLES_FAILED: {
      return { ...state, pages: null, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
