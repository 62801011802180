import React from 'react';

const Messages = React.lazy(
  () => import('../../modules/employee/Messages/containers/Messages')
);
const Conversation = React.lazy(
  () => import('../../modules/employee/Messages/containers/conversation')
);
const NewMessage = React.lazy(
  () => import('../../modules/employee/Messages/containers/NewMessage')
);
const Sucessmsg = React.lazy(
  () => import('../../modules/employee/Messages/containers/Successmsg')
);

export const message = {
  path: '/messages',
  component: Messages
};
export const conversation = {
  path: '/conversation',
  component: Conversation
};
export const new_message = {
  path: '/newmessage',
  component: NewMessage
};
export const sucessmsg = {
  path: '/send',
  component: Sucessmsg
};
