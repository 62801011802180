import React from 'react';

const Support = React.lazy(
  () => import('../../modules/moderator/support/containers/Support')
);

export const support = {
  path: '/support',
  component: Support
};
