export const ACTIONS = {
  FETCH_EMPLOYEES_INIT: 'FETCH_EMPLOYEES_INIT',
  FETCH_EMPLOYEES_SUCCEDED: 'FETCH_EMPLOYEES_SUCCEDED',
  FETCH_EMPLOYEES_FAILED: 'FETCH_EMPLOYEES_FAILED',

  CLEAR_EMPLOYEE_FORM: 'CLEAR_EMPLOYEE_FORM',

  CREATE_EMPLOYEE_INIT: 'CREATE_EMPLOYEE_INIT',
  CREATE_EMPLOYEE_SUCCEDED: 'CREATE_EMPLOYEE_SUCCEDED',
  CREATE_EMPLOYEE_FAILED: 'CREATE_EMPLOYEE_FAILED',

  FETCH_EMPLOYEE_INIT: 'FETCH_EMPLOYEE_INIT',
  FETCH_EMPLOYEE_SUCCEDED: 'FETCH_EMPLOYEE_SUCCEDED',
  FETCH_EMPLOYEE_FAILED: 'FETCH_EMPLOYEE_FAILED',

  EDIT_EMPLOYEE_INIT: 'EDIT_EMPLOYEE_INIT',
  EDIT_EMPLOYEE_SUCCEDED: 'EDIT_EMPLOYEE_SUCCEDED',
  EDIT_EMPLOYEE_FAILED: 'EDIT_EMPLOYEE_FAILED',

  DELETE_EMPLOYEE_INIT: 'DELETE_EMPLOYEE_INIT',
  DELETE_EMPLOYEE_SUCCEDED: 'DELETE_EMPLOYEE_SUCCEDED',
  DELETE_EMPLOYEE_FAILED: 'DELETE_EMPLOYEE_FAILED',

  FILTER_EMPLOYEES: 'FILTER_EMPLOYEES',

  CREATE_EMPLOYEE_INVITATION_INIT: 'CREATE_EMPLOYEE_INVITATION_INIT',
  CREATE_EMPLOYEE_INVITATION_SUCCEDED: 'CREATE_EMPLOYEE_INVITATION_SUCCEDED',
  CREATE_EMPLOYEE_INVITATION_FAILED: 'CREATE_EMPLOYEE_INVITATION_FAILED',

  RESET_EMPLOYEE_INIT: 'RESET_EMPLOYEE_INIT',
  RESET_EMPLOYEE_SUCCEDED: 'RESET_EMPLOYEE_SUCCEDED',
  RESET_EMPLOYEE_FAILED: 'RESET_EMPLOYEE_FAILED'
};

export const ENDPOINT = {
  EMPLOYEE_INVITATIONS: '/api/department/:departmentParam/employee-invitations',
  EMPLOYEES: '/api/department/:departmentParam/employees',
  EMPLOYEE: '/api/department/:departmentParam/employee/:param',
  RESET_EMPLOYEE: '/api/employee/reset/:param'
};
