export const ACTIONS = {
  FETCH_MANAGERS_INIT: 'FETCH_MANAGERS_INIT',
  FETCH_MANAGERS_SUCCEDED: 'FETCH_MANAGERS_SUCCEDED',
  FETCH_MANAGERS_FAILED: 'FETCH_MANAGERS_FAILED',

  CLEAR_MANAGER_FORM: 'CLEAR_MANAGER_FORM',

  CREATE_MANAGER_INIT: 'CREATE_MANAGER_INIT',
  CREATE_MANAGER_SUCCEDED: 'CREATE_MANAGER_SUCCEDED',
  CREATE_MANAGER_FAILED: 'CREATE_MANAGER_FAILED',

  FETCH_MANAGER_INIT: 'FETCH_MANAGER_INIT',
  FETCH_MANAGER_SUCCEDED: 'FETCH_MANAGER_SUCCEDED',
  FETCH_MANAGER_FAILED: 'FETCH_MANAGER_FAILED',

  EDIT_MANAGER_INIT: 'EDIT_MANAGER_INIT',
  EDIT_MANAGER_SUCCEDED: 'EDIT_MANAGER_SUCCEDED',
  EDIT_MANAGER_FAILED: 'EDIT_MANAGER_FAILED',

  DELETE_MANAGER_INIT: 'DELETE_MANAGER_INIT',
  DELETE_MANAGER_SUCCEDED: 'DELETE_MANAGER_SUCCEDED',
  DELETE_MANAGER_FAILED: 'DELETE_MANAGER_FAILED',

  FILTER_MANAGERS: 'FILTER_MANAGERS'
};

export const ENDPOINT = {
  MANAGERS: '/api/company/:companyParam/managers',
  MANAGER: '/api/company/:companyParam/manager/:param'
};
