import React from 'react';

const ContactList = React.lazy(
  () => import('../../modules/admin/subadmin/containers/ContactList')
);

export const subContactList = {
  path: '/contacts',
  component: ContactList
};
