import { ACTIONS } from '../constants';

export default (
  state = {
    isLoading: false,
    companyGeneralStats: null,
    error: null
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.FETCH_COMPANY_GENERAL_STATS_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.FETCH_COMPANY_GENERAL_STATS_SUCCEDED: {
      return {
        ...state,
        companyGeneralStats: payload,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_COMPANY_GENERAL_STATS_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        companyGeneralStats: null
      };
    }

    case ACTIONS.FETCH_DEPARTMENT_GENERAL_STATS_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.FETCH_DEPARTMENT_GENERAL_STATS_SUCCEDED: {
      return {
        ...state,
        companyGeneralStats: payload,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_DEPARTMENT_GENERAL_STATS_FAILED: {
      return {
        ...state,
        error: payload,
        isLoading: false,
        companyGeneralStats: null
      };
    }

    default: {
      return state;
    }
  }
};
