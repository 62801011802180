export const ACTIONS = {
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT: 'RETREIVE_QUESTIONNAIRE_EMPLOYEE_INIT',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_SUCCEDED',
  RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED:
    'RETREIVE_QUESTIONNAIRE_EMPLOYEE_FAILED',

  FETCH_MESSAGES_INIT: 'FETCH_MESSAGES_INIT',
  FETCH_MESSAGES_SUCCEDED: 'FETCH_MESSAGES_SUCCEDED',
  FETCH_MESSAGES_FAILED: 'FETCH_MESSAGES_FAILED',

  POST_NEW_MESSAGES_INIT: 'POST_NEW_MESSAGES_INIT',
  POST_NEW_MESSAGES_SUCCEDED: 'POST_NEW_MESSAGES_SUCCEDED',
  POST_NEW_MESSAGES_FAILED: 'POST_NEW_MESSAGES_FAILED',

  READ_MESSAGES_INIT: 'FETCH_MESSAGES_INIT',
  READ_MESSAGES_SUCCEDED: 'READ_MESSAGES_SUCCEDED',
  READ_MESSAGES_FAILED: 'READ_MESSAGES_FAILED'
};

export const ENDPOINT = {
  RETREIVE_QUESTIONNAIRE_EMPLOYEE: '/api/employee/questionnaire',
  FETCH_MESSAGES_EMPLOYEE: '/api/employee/:param/messages',
  READ_MESSAGES: '/api/message/:param/read'
};
