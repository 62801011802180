import React from 'react';
import notif from 'react-notification-system-redux';
import {
  CALL_API,
  CALL_FOOD_API,
  CALL_QUESTION_API,
  LOCAL_CALL
} from '../constants';
import { makeBaseCall, makeFoodQuestion, makeQuestionCall } from '../utils/api';
import { logout } from '../modules/anonymous/authentication/actions';
import i18n from '../i18n';

import { NotificationComponent } from '../components/NotificationComponent';

export const api = (store) => (next) => async (action) => {
  if (!action || action.type !== CALL_API) {
    return next(action);
  }

  const dispatch = dispatchActions(next);
  const {
    actions,
    messages = {},
    endpoint,
    method,
    jwt,
    params = {},
    extraData = {},
    userId
  } = action.meta;
  const {
    user: { access_token: accessToken = null, id }
  } = store.getState().session;
  const pathname = window.location.pathname;
  if (jwt && !accessToken && pathname !== '/auth/login') {
    next(
      notif.error(
        NotificationComponent(
          'Oops!',
          i18n.t('Vous êtes déconnectés. Connectez-vous et réessayez'),
          false
        )
      )
    );
    return;
  }

  if (actions.init) {
    dispatch(actions.init, params);
  }

  let headers = {};
  if (jwt) {
    headers = { Authorization: `Bearer  ${accessToken}` };
  }
  if (userId) {
    makeBaseCall(
      method,
      `/api/employee/${id}/questionnaires`,
      action.payload,
      headers,
      params
    )
      .then((resp) => {
        if (messages.success) {
          next(
            notif.success(
              NotificationComponent(i18n.t('Bravo !'), messages.success, true)
            )
          );
        }
        dispatch(actions.success, Object.assign({}, resp.data, extraData));
      })
      .catch((err) => {
        if (messages.fail) {
          next(
            notif.error(NotificationComponent('Oops !', messages.fail, false))
          );
        }
        dispatch(actions.fail, (err.response && err.response.data) || {});
        if (
          err.response?.status === 401 ||
          err.response?.status === 403 ||
          err.response?.status === 422
        ) {
          store.dispatch(logout());
        }
      });
    return;
  }
  makeBaseCall(method, endpoint, action.payload, headers, params)
    .then((resp) => {
      if (messages.success) {
        next(
          notif.success(
            NotificationComponent(i18n.t('Bravo !'), messages.success, true)
          )
        );
      }
      dispatch(actions.success, Object.assign({}, resp.data, extraData));
    })
    .catch((err) => {
      if (messages.fail) {
        next(
          notif.error(NotificationComponent('Oops !', messages.fail, false))
        );
      }
      dispatch(actions.fail, (err.response && err.response.data) || {});
      if (
        err.response?.status === 401 ||
        err.response?.status === 403 ||
        err.response?.status === 422
      ) {
        store.dispatch(logout());
      }
    });
};

export const questionApi = (store) => (next) => async (action) => {
  if (!action || action.type !== CALL_QUESTION_API) {
    return next(action);
  }

  const dispatch = dispatchActions(next);
  const {
    actions,
    headers,
    endpoint,
    method,
    params = {},
    extraData = {}
  } = action.meta;

  if (actions.init) {
    dispatch(actions.init, params);
  }

  makeQuestionCall(method, endpoint, action.payload, headers, params)
    .then((resp) => {
      dispatch(actions.success, Object.assign({}, resp.data, extraData));
    })
    .catch((err) => {
      dispatch(actions.fail, (err.response && err.response.data) || {});
    });
};

export const questionFoodApi = (store) => (next) => async (action) => {
  if (!action || action.type !== CALL_FOOD_API) {
    return next(action);
  }
  let token = null;
  const dispatch = dispatchActions(next);
  const {
    actions,
    endpoint,
    method,
    params = {},
    extraData = {}
  } = action.meta;

  if (actions.init) {
    dispatch(actions.init, params);
  }
  if (!token) {
    const response = await fetch(
      'https://predicta.fulltech.io/api/login_check',
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: '&20HelloPredict20!',
          username: 'admin@predict-a.com',
          _remember_me: ''
        })
      }
    ).then((resp) => resp.json());
    token = response.token;
  }
  let headers = { Authorization: `Bearer ${token}` };

  makeFoodQuestion(method, endpoint, action.payload, headers, params)
    .then((resp) => {
      dispatch(actions.success, Object.assign({}, resp.data, extraData));
    })
    .catch((err) => {
      dispatch(actions.fail, (err.response && err.response.data) || {});
    });
};

const dispatchActions =
  (next) =>
  (action, payload = {}) => {
    if (!Array.isArray(action)) return next({ type: action, payload });
    return action.map((a) => next({ type: a, payload }));
  };

export const localCall = (store) => (next) => async (action) => {
  if (!action || action.type !== LOCAL_CALL) {
    return next(action);
  }

  const dispatch = dispatchActions(next);
  const { actions, messages = {} } = action.meta;

  if (actions) {
    dispatch(actions.success);
  }

  if (messages.success) {
    next(
      notif.success(
        NotificationComponent(i18n.t('Bravo !'), messages.success, true)
      )
    );
  }
};
