import { ROLES } from '../../constants';

import * as dashbord from './dashbord';
import * as department from './department';
import * as employee from './employee';
import * as inviteEmployee from './inviteEmployee';
import * as rps from './rps';
import * as tms from './tms';
import * as ergonomics from './ergonomics';
import * as physical from './physical';
import * as nutrition from './nutrition';
import * as confidentiality from './confidentiality';
import * as support from './support';
import * as password from './change_password';
import * as generalData from './generalData';
export default {
  path: '/moderator',
  role: ROLES.MODERATOR,
  routes: {
    ...dashbord,
    ...department,
    ...employee,
    ...rps,
    ...tms,
    ...ergonomics,
    ...physical,
    ...nutrition,
    ...inviteEmployee,
    ...confidentiality,
    ...support,
    ...password,
    ...generalData
  }
};
