import React from 'react';

const AdminList = React.lazy(
  () => import('../../modules/admin/subadmin/containers/AdminList')
);
const AdminNew = React.lazy(
  () => import('../../modules/admin/subadmin/containers/AdminNew')
);
const AdminEdit = React.lazy(
  () => import('../../modules/admin/subadmin/containers/AdminEdit')
);

export const subadminList = {
  path: '/admins',
  component: AdminList
};

export const adminNew = {
  path: '/new',
  component: AdminNew
};

export const adminEdit = {
  path: '/:param/edit',
  component: AdminEdit
};
