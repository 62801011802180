export const ACTIONS = {
  GET_CHECKIN_SPORT_INIT: 'GET_CHECKIN_SPORT_INIT',
  GET_CHECKIN_SPORT_SUCCEDED: 'GET_CHECKIN_SPORT_SUCCEDED',
  GET_CHECKIN_SPORT_FAILED: 'GET_CHECKIN_SPORT_FAILED',

  SAVE_CHECKIN_SPORT_INIT: 'SAVE_CHECKIN_SPORT_INIT',
  SAVE_CHECKIN_SPORT_SUCCEDED: 'SAVE_CHECKIN_SPORT_SUCCEDED',
  SAVE_CHECKIN_SPORT_FAILED: 'SAVE_CHECKIN_SPORT_FAILED'
};

export const ENDPOINT = {
  SAVE_CHECKIN_SPORT: '/api/employee/checkins/sport',
  GET_CHECKIN_SPORT: '/api/employee/checkins/sport'
};
