import { ROLES } from './../../constants';

import * as questionnaireDashboard from './questionnaire_dashboard';
import * as medicalRecord from './medicalRecord';
import * as questionnaire from './questionnaire';
import * as dashboard from './dashboard';
import * as results from './results';
import * as message from './messages';
import * as confidentialty from './confidentiality';
import * as mediaLibrary from './mediaLibrary';
import * as nutrition from './nutrition';
import * as checkins from './checkins';
import * as adverse_events from './events_risks';
import * as risk_factors from './risk_factors';
import * as programs from './programs';
import * as scheduler from './scheduler';
import * as evolution from './evolution';
import * as updateEmail from './updateEmail';
import * as rpsQuestionnaire from './rpsQuestionnaire';

export default {
  path: '/employee',
  role: ROLES.EMPLOYEE,

  routes: {
    ...questionnaireDashboard,
    ...medicalRecord,
    ...questionnaire,
    ...dashboard,
    ...results,
    ...adverse_events,
    ...risk_factors,
    ...programs,
    ...scheduler,
    ...message,
    ...confidentialty,
    ...mediaLibrary,
    ...nutrition,
    ...checkins,
    ...evolution,
    ...updateEmail,
    ...rpsQuestionnaire
  }
};
