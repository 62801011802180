import React from 'react';

const RiskPage = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskPage')
);
const RiskPain = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskPain')
);
const RiskSedentary = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskSedentary')
);
const RiskSmoking = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskSmoking')
);
const RiskSleep = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskSleep')
);
const RiskStress = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskStress')
);
const RiskObesity = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskObesity')
);
const RiskHandling = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskHandling')
);
const RiskWorkload = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskWorkload')
);
const RiskDissatisfaction = React.lazy(
  () =>
    import('../../modules/employee/risk_factors/containers/riskDissatisfaction')
);
const RiskAlcohol = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskAlcohol')
);
const RiskDepression = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskDepression')
);
const RiskSolitude = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskSolitude')
);
const RiskNutrition = React.lazy(
  () => import('../../modules/employee/risk_factors/containers/riskNutrition')
);

export const riskPage = {
  path: '/risk-factors',
  component: RiskPage
};

export const riskPain = {
  path: '/risk-factor/douleur',
  component: RiskPain
};

export const riskSedentary = {
  path: '/risk-factor/sedentary-lifestyle',
  component: RiskSedentary
};

export const riskSmoking = {
  path: '/risk-factor/smoking',
  component: RiskSmoking
};

export const riskSleep = {
  path: '/risk-factor/sleep',
  component: RiskSleep
};

export const riskStress = {
  path: '/risk-factor/stress',
  component: RiskStress
};

export const riskObesity = {
  path: '/risk-factor/overweight',
  component: RiskObesity
};

export const riskHandling = {
  path: '/risk-factor/handling',
  component: RiskHandling
};

export const riskWorkload = {
  path: '/risk-factor/workload',
  component: RiskWorkload
};

export const riskDissatisfaction = {
  path: '/risk-factor/dissatisfaction',
  component: RiskDissatisfaction
};

export const riskAlcohol = {
  path: '/risk-factor/alcohol',
  component: RiskAlcohol
};

export const riskDepression = {
  path: '/risk-factor/depression',
  component: RiskDepression
};

export const riskSolitude = {
  path: '/risk-factor/solitude',
  component: RiskSolitude
};

export const riskNutrition = {
  path: '/risk-factor/nutrition',
  component: RiskNutrition
};
