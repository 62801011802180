import {
  CALL_API,
  DELETE_SESSION,
  HTTP_METHODS,
  LOCAL_CALL,
  SAVE_SESSION
} from '../../../../constants';
import { ACTIONS, ENDPOINT } from '../constants';
import i18n from '../../../../i18n';

export const clearError = () => ({
  type: ACTIONS.CLEAR_ERRORS
});

export const login = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.ACCOUNT_LOGIN_INIT,
      success: [SAVE_SESSION, ACTIONS.ACCOUNT_LOGIN_SUCCEDED],
      fail: ACTIONS.ACCOUNT_LOGIN_FAILED
    },
    messages: {
      fail: i18n.t('Email ou mot de passe invalide, veuillez réessayer')
    },
    endpoint: ENDPOINT.LOGIN,
    method: HTTP_METHODS.POST
  }
});

export const verifyEmailForgotPassword = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.EMAIL_FORGOT_PASSWORD_INIT,
      success: ACTIONS.EMAIL_FORGOT_PASSWORD_SUCCEDED,
      fail: ACTIONS.EMAIL_FORGOT_PASSWORD_FAILED
    },
    messages: {
      fail: i18n.t("Une erreur s'est produite. Veuillez réessayer"),
      success: i18n.t('Vérifiez votre boîte mail')
    },
    endpoint: ENDPOINT.FORGOT_PASSWORD_EMAIL,
    method: HTTP_METHODS.POST
  }
});

export const resetPassword = (payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.EMAIL_FORGOT_PASSWORD_INIT,
      success: ACTIONS.EMAIL_FORGOT_PASSWORD_SUCCEDED,
      fail: ACTIONS.EMAIL_FORGOT_PASSWORD_FAILED
    },
    messages: {
      fail: i18n.t('les deux mots de passe ne correspondent pas'),
      success: i18n.t('Votre mot de passe est modifié avec succés')
    },
    endpoint: ENDPOINT.RESET_PASSWORD,
    method: HTTP_METHODS.POST
  }
});

export const fetchInvitation = (token) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ACCOUNT_INVITATION_INIT,
      success: ACTIONS.FETCH_ACCOUNT_INVITATION_SUCCEDED,
      fail: ACTIONS.FETCH_ACCOUNT_INVITATION_FAILED
    },
    endpoint: ENDPOINT.INVITATION.replace(':param', token),
    method: HTTP_METHODS.GET
  }
});

export const register = (token, payload) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_ACCOUNT_INIT,
      success: [SAVE_SESSION, ACTIONS.CREATE_ACCOUNT_SUCCEDED],
      fail: ACTIONS.CREATE_ACCOUNT_FAILED
    },
    messages: {
      fail: i18n.t('Un compte existe déjà avec cette adresse mail')
    },
    endpoint: ENDPOINT.REGISTER.replace(':param', token),
    method: HTTP_METHODS.POST
  }
});

// export const logout = () =>
//   ({
//     type: CALL_API,
//     meta: {
//       actions: {
//         init: ACTIONS.ACCOUNT_LOGOUT_INIT,
//         success: [DELETE_SESSION, ACTIONS.ACCOUNT_LOGOUT_SUCCEDED, ACTIONS.CLEAN_CACH],
//         fail: ACTIONS.ACCOUNT_LOGOUT_FAILED
//       },
//       messages: {
//         success: i18n.t("Vous avez été déconnecté avec succès"),
//         fail: i18n.t("Une erreur s'est produite. Veuillez réessayer")
//       },
//       endpoint: ENDPOINT.LOGOUT,
//       method: HTTP_METHODS.POST,
//       jwt: true
//     }
//   })

export const logout = () => ({
  type: LOCAL_CALL,
  meta: {
    actions: {
      success: [
        DELETE_SESSION,
        ACTIONS.ACCOUNT_LOGOUT_SUCCEDED,
        ACTIONS.CLEAN_CACH
      ]
    },
    messages: {
      success: i18n.t('Vous avez été déconnecté avec succès')
    }
  }
});
