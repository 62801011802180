import anonymousRoutes from './anonymous';
import adminRoutes from './admin';
import moderatorRoutes from './moderator';
import employeeRoutes from './employee';
import publicRoutes from './public';

export default {
  anonymous: anonymousRoutes,
  admin: adminRoutes,
  moderator: moderatorRoutes,
  employee: employeeRoutes,
  public: publicRoutes
};
