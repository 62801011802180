import { ACTIONS } from '../constants';

export default (
  state = {
    items: [],
    item: {},
    page: 0,
    isLoading: false,
    searchTerm: '',
    hasMore: true,
    error: null,
    permissions: []
  },
  action
) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_ADMIN_FORM: {
      return { ...state, item: payload, isLoading: false, error: null };
    }

    case ACTIONS.FETCH_ADMINS_INIT: {
      state.items = payload.page === 1 ? [] : state.items;
      return { ...state, isLoading: true, hasMore: false, error: null };
    }
    case ACTIONS.FETCH_ADMINS_SUCCEDED: {
      const { items, has_more: hasMore, page } = payload;
      return {
        ...state,
        items: page === 1 ? items : [...state.items, ...items],
        page,
        hasMore: hasMore || false,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_ADMINS_FAILED: {
      return {
        ...state,
        items: [],
        isLoading: false,
        hasMore: false,
        page: 1,
        error: payload
      };
    }

    case ACTIONS.CREATE_ADMIN_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.CREATE_ADMIN_SUCCEDED: {
      const { uid: param } = payload;
      return {
        ...state,
        item: { param },
        items: state.items.concat(payload),
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.CREATE_ADMIN_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.EDIT_ADMIN_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.EDIT_ADMIN_SUCCEDED: {
      return {
        ...state,
        item: payload,
        items: state.items.map((item) =>
          item.id === payload.id ? payload : item
        ),
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.EDIT_ADMIN_FAILED: {
      return { ...state, error: payload, isLoading: false };
    }

    case ACTIONS.FETCH_ADMIN_INIT: {
      return { ...state, isLoading: true, item: null, error: null };
    }
    case ACTIONS.FETCH_ADMIN_SUCCEDED: {
      return { ...state, item: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.FILTER_ADMINS: {
      return { ...state, searchTerm: payload.searchTerm };
    }

    case ACTIONS.DELETE_ADMIN_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.DELETE_ADMIN_SUCCEDED: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.DELETE_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: null };
    }

    case ACTIONS.FETCH_ADMIN_PERMISSIONS_INIT: {
      return {
        ...state,
        permissions: [],
        isLoading: true,
        hasMore: false,
        error: null
      };
    }
    case ACTIONS.FETCH_ADMIN_PERMISSIONS_SUCCEDED: {
      const { items } = payload;
      return {
        ...state,
        permissions: items,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_ADMIN_PERMISSIONS_FAILED: {
      return {
        ...state,
        items: [],
        isLoading: false,
        error: payload
      };
    }

    case ACTIONS.FETCH_CONTACTS_INIT: {
      state.items = payload.page === 1 ? [] : state.items;
      return { ...state, isLoading: true, hasMore: false, error: null };
    }
    case ACTIONS.FETCH_CONTACTS_SUCCEDED: {
      const { items, has_more: hasMore, page } = payload;
      return {
        ...state,
        items: page === 1 ? items : [...state.items, ...items],
        page,
        hasMore: hasMore || false,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_CONTACTS_FAILED: {
      return {
        ...state,
        items: [],
        isLoading: false,
        hasMore: false,
        page: 1,
        error: payload
      };
    }

    case ACTIONS.DELETE_CONTACT_INIT: {
      return { ...state, isLoading: true, error: null };
    }
    case ACTIONS.DELETE_CONTACT_SUCCEDED: {
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.DELETE_CONTACT_FAILED: {
      return { ...state, isLoading: false, error: null };
    }

    case ACTIONS.FETCH_QUOTES_INIT: {
      state.items = payload.page === 1 ? [] : state.items;
      return { ...state, isLoading: true, hasMore: false, error: null };
    }
    case ACTIONS.FETCH_QUOTES_SUCCEDED: {
      const { items, has_more: hasMore, page } = payload;
      return {
        ...state,
        items: page === 1 ? items : [...state.items, ...items],
        page,
        hasMore: hasMore || false,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_QUOTES_FAILED: {
      return {
        ...state,
        items: [],
        isLoading: false,
        hasMore: false,
        page: 1,
        error: payload
      };
    }
    case ACTIONS.FETCH_FORMATIONS_INIT: {
      state.items = payload.page === 1 ? [] : state.items;
      return { ...state, isLoading: true, hasMore: false, error: null };
    }
    case ACTIONS.FETCH_FORMATIONS_SUCCEDED: {
      const { items, has_more: hasMore, page } = payload;
      return {
        ...state,
        items: page === 1 ? items : [...state.items, ...items],
        page,
        hasMore: hasMore || false,
        isLoading: false,
        error: null
      };
    }
    case ACTIONS.FETCH_FORMATIONS_FAILED: {
      return {
        ...state,
        items: [],
        isLoading: false,
        hasMore: false,
        page: 1,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
};
