export const COMPANY_TYPE = [
  { label: 'Public Company', value: 'Public Company' },
  { label: 'Educational', value: 'Educational' },
  { label: 'Self Employed', value: 'Self Employed' },
  { label: 'Government Agency', value: 'Government Agency' },
  { label: 'Non Profit', value: 'Non Profit' },
  { label: 'Self Owned', value: 'Self Owned' },
  { label: 'Privately Held', value: 'Privately Held' },
  { label: 'Partnership', value: 'Partnership' }
];

export const COMPANY_STATUS = [
  { label: 'Operating', value: 'Operating' },
  { label: 'Operating Subsidiary', value: 'Operating Subsidiary' },
  { label: 'Reorganizing', value: 'Reorganizing' },
  { label: 'Out of Business', value: 'Out of Business' },
  { label: 'Acquired', value: 'Acquired' }
];

export const COMPANY_RANGE = [
  { label: '1', value: '1' },
  { label: '2-10', value: '2-10' },
  { label: '11-50', value: '11-50' },
  { label: '51-200', value: '51-200' },
  { label: '201-500', value: '201-500' },
  { label: '501-1000', value: '501-1000' },
  { label: '1001-5000', value: '1001-5000' },
  { label: '5001-10,000', value: '5001-10,000' },
  { label: '10,000+', value: '10,000+' }
];
